import React from "react"
import team from "./images/team.png"
import CountUp from "react-countup"
import Button from "../../Button/Button"
const WhyChooseUs = () => {
    const scrollToForm = () => {
        const form = document.getElementById("page-form")
        form.scrollIntoView({ behavior: "smooth" })
    }
    return (
        <div className="container  ">
            <div className="flex flex-wrap justify-between gap-y-14 rounded-lg bg-[#011629] py-14 px-10 pb-16 md:px-8 md:pb-14 lg:px-10">
                <div className="flex w-full flex-col gap-6  text-white md:w-1/2 md:pr-20 lg:pr-24">
                    <h2 className="mb-2 mt-2 text-3xl font-semibold lg:text-5xl">
                        Why choose us?
                    </h2>
                    <h3 className="border-b border-white pb-4 text-base font-medium leading-[26px] md:text-md">
                        A full-service digital agency, with over 20 years experience creating exceptional web solutions for our clients.
                    </h3>
                    <h3 className="border-b border-white pb-4 text-base font-medium leading-[26px] md:text-md">
                        A team of experts who care and will understand your business and goals.
                    </h3>
                    <h3 className="mb-6 text-base font-medium leading-[26px] md:text-md">
                        Our mission is to accelerate business growth through
                        innovative, yet simplified solutions.
                    </h3>
                    <Button
                        onClick={scrollToForm}
                        type="blue"
                        className="max-w-[223px] !py-3"
                    >
                        Book a call
                    </Button>
                </div>

                <div className="w-full pb-10 md:w-1/2 md:pb-0 xl:mr-10 xl:w-[calc(50%-90px)]">
                    <img
                        src={team}
                        className="aspect-[475/350]  max-h-[350px] w-full object-cover md:max-h-[350px] "
                        alt="team"
                    />
                    {/* <div className="mt-6 flex justify-start gap-5 md:justify-center md:gap-8 lg:gap-6 ">
                        <div className="text-center  lg:min-w-[115px]">
                            <span className="text-3xl font-semibold text-white md:text-5xl">
                                <CountUp
                                    end={20}
                                    enableScrollSpy
                                    duration={5}
                                ></CountUp>
                                +
                            </span>
                            <p className="text-sub font-semibold  text-subtitle-color">
                                years exp
                            </p>
                        </div>
                        <div className="text-center lg:min-w-[115px]">
                            <span className="text-3xl font-semibold text-white md:text-5xl">
                                <CountUp
                                    end={100}
                                    delay={300}
                                    enableScrollSpy
                                    duration={4}
                                />
                                +
                            </span>
                            <p className="font-semibold text-subtitle-color ">
                                projects
                            </p>
                        </div>
                        <div className="text-center lg:min-w-[115px]">
                            <span className="text-3xl font-semibold text-white md:text-5xl">
                                <CountUp
                                    end={12}
                                    delay={500}
                                    enableScrollSpy
                                    duration={5}
                                />
                                +
                            </span>
                            <p className="font-semibold text-subtitle-color ">
                                clients
                            </p>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default WhyChooseUs
