import React, { useState, useEffect, useRef } from "react"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import parse from "html-react-parser"
import mockup from "./images/mockup.png"
import hp from "./images/Hp-Elite.png"
import mac from "./images/Mac-Studio.png"
import ipad from "./images/iPad-Mini.png"
import Card from "./card"
import { matchMediaScreen } from "./card"
const cards = [
    {
        id: 1,
        image: hp,
        title: "Centralised Data Visualisation",
        description: `The SD Performance Dashboard provides a centralised platform for visualising and analysing data from Google Analytics and Google Search Console. 
        <br/>
        <p className="mt-2"> It allows you to view your website's data in <span className="text-primary-color font-semibold"> interactive 
         dashboards</span> and reports that combine data from multiple sources, giving you a comprehensive view of your <span className="text-primary-color font-semibold"> website's performance </span>
          and user behaviour. </p>`
    },
    {
        id: 2,
        image: mac,
        title: "Saigon Digital Performance Dashboard",
        description: `The <span className="text-black font-semibold"> Performance Dashboard </span> is built on Google Looker Studio and enables our clients to leverage their data effectively,
        <span className="text-primary-color font-semibold"> gain insights </span>, and make <span className="text-primary-color font-semibold">  data-driven </span> decisions.<br/>
        <p className="mt-2"> It combines powerful analytics capabilities with ease of use, collaboration features, and robust security, making it a comprehensive solution for data analysis and visualisation.</span>`
    },
    {
        id: 3,
        image: ipad,
        title: "Customisable Reporting",
        description: `Performance Dashboard integrates with Googles Looker Studio and enables you to create  <span className="text-primary-color font-semibold"> highly customisable </span> reports tailored to your specific needs. 
        <p className="mt-2"> You can choose from a wide range of visualisation options, such as charts, graphs, and tables, and easily customise the layout and appearance of your reports. This flexibility allows you to present data in a way that is  <span className="text-primary-color font-semibold"> meaningful </span> and 
        <span className="text-primary-color font-semibold"> actionable</span> for your business.</span>`
    }
]

const BenefitHighlight = ({ className }) => {
    const caseRef = useRef(null)
    const targetRef = useRef(null)

    const [activeCase, setActiveCase] = useState(cards?.at(0)?.id)
    const [targetEl, setTargetEl] = useState(null)
    useEffect(() => {
        const mm = gsap.matchMedia()
        mm.add(
            matchMediaScreen.large,
            () => {
                gsap.set(targetRef.current, {
                    imageRendering: true
                })
            },
            caseRef
        )

        setTargetEl(targetRef)

        return () => mm.revert()
    }, [])
    return (
        <div
            className={`container relative flex flex-col items-center gap-0 py-20 lg:py-28 ${className}`}
        >
            <p className="mb-4 max-w-[713px] text-center text-2xl font-semibold leading-[28px] text-primary-color">
                A comprehensive full service solution
            </p>
            <h2 className="mb-14 max-w-[835px] text-center text-2xl font-semibold md:text-4xl lg:text-5xl lg:leading-[60px]">
                Experience the benefits of the Business Growth Accelerator...
            </h2>
            <div className="relative">
                <div className="invisible absolute inset-0 top-[-25vh] z-[1] lg:visible">
                    <div className="sticky -top-10 h-screen">
                        {/* Width equal to the card image */}
                        <div
                            className="grid h-full w-[calc(50%-40px)] place-items-center"
                            style={{ perspective: "1800px" }}
                        >
                            <div
                                ref={targetRef}
                                className="relative aspect-[500/440] h-1/2 w-full origin-top rounded-[20px] bg-white "
                            >
                                <div className="relative aspect-[500/440] w-full bg-white">
                                    <img
                                        src={mockup}
                                        alt="mockup"
                                        className="absolute inset-0   w-full"
                                    />
                                </div>
                                <div className="  h-full w-full overflow-hidden rounded-b-[20px]  ">
                                    {cards?.map(({ image, id }) => {
                                        return (
                                            <img
                                                key={id}
                                                src={image}
                                                className={`absolute inset-0 top-1/3 left-1/2 aspect-video w-[87%] -translate-x-1/2 -translate-y-1/3 object-contain ${
                                                    id === activeCase
                                                        ? "block"
                                                        : "hidden"
                                                }`}
                                                alt={" case studies"}
                                            />
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-y-10 lg:gap-y-20">
                    {cards?.map((item, index) => {
                        const reverse = !(index % 2 === 0)
                        return (
                            <Card
                                {...item}
                                key={item?.id}
                                targetEl={targetEl}
                                setActiveCase={setActiveCase}
                                reserve_layout={reverse}
                            />
                        )
                    })}
                </div>
                <div className="hidden h-[30vh] lg:block"></div>
            </div>
        </div>
    )
}

export default BenefitHighlight
