import gsap from "gsap"
import ScrollTrigger from "gsap/dist/ScrollTrigger"
import React, { useMemo } from "react"
import { useEffect } from "react"

if (typeof window !== undefined) {
    gsap.registerPlugin(ScrollTrigger)
}

const ProgressCircle = ({ title, progress, className }) => {
    let bg = { backgroundInner: "", backgroundOuter: "", textColor: "" }
    if (progress >= 90) {
        bg.backgroundInner = "#EFF9F0"
        bg.backgroundOuter = "#2ACDA0"
        bg.textColor = "#2ACDA0"
    } else if (progress < 90) {
        bg.backgroundOuter = "#fa3"
        bg.backgroundInner = "#FEF7ED"
        bg.textColor = " #B43F1C"
    }

    return (
        <div
            className={`flex aspect-square w-full items-start justify-center `}
        >
            <div
                style={{ background: bg?.backgroundInner }}
                className="ko-progress-circle relative"
                data-progress={0}
            >
                <div className={`ko-circle ${className}`}>
                    <div
                        style={{ background: bg?.backgroundInner }}
                        className="full ko-progress-circle__slice"
                    >
                        <div
                            style={{ background: bg.backgroundOuter }}
                            className="ko-progress-circle__fill"
                        />
                    </div>
                    <div
                        style={{ background: bg?.backgroundInner }}
                        className="ko-progress-circle__slice"
                    >
                        <div
                            style={{ background: bg.backgroundOuter }}
                            className="ko-progress-circle__fill"
                        />
                        <div
                            style={{ background: bg.backgroundOuter }}
                            className="ko-progress-circle__fill ko-progress-circle__bar"
                        />
                    </div>
                </div>
                <div
                    style={{ background: bg.backgroundInner }}
                    className="ko-progress-circle__overlay"
                />

                <span
                    style={{ color: bg.textColor }}
                    className="absolute inset-0 grid place-items-center text-2xl font-semibold text-[#2ACDA0]"
                >
                    {progress}
                </span>

                <p className="absolute top-full left-1/2 mt-2 -translate-x-1/2 whitespace-nowrap text-xl font-semibold">
                    {title}
                </p>
            </div>
        </div>
    )
}

export default ProgressCircle
