import React, { useState } from "react"
import axios from "axios"
import Button from "../Button/Button"
import { toast } from "react-toastify"
import CONSTANT_INFO from "../../constant/constant"
import SpinnerLoader from "../Loader/SpinnerLoader"
const categories = []
const HeroForm = ({
    context = {
        pageName: "Saigon Digital",
        pageUri: "https://saigon.digital/"
    }
}) => {
    const [loadingForm, setLoadingForm] = useState()

    const handleOnSubmit = async e => {
        e.preventDefault()
        const form = e.target
        setLoadingForm(true)
        const formData = new FormData(e.target)

        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        }
        console.log(process.env.GATSBY_HUBSPOT_FORM_NEWLANDING)
        console.log(CONSTANT_INFO.curl.hubspot_form_newlanding)

        const fields = [
            {
                name: "firstname",
                value: formData.get("name")
            },

            {
                name: "email",
                value: formData.get("email")
            }
        ]
        console.log(fields)
        await axios
            .post(
                CONSTANT_INFO.curl.hubspot_form_newlanding,
                {
                    portalId: process.env.GATSBY_HUBSPOT_PORTAL,
                    formGuid: process.env.GATSBY_HUBSPOT_FORM_NEWLANDING,
                    context: context,
                    fields
                },
                config
            )
            .then(res => {
                toast(res?.data?.inlineMessage, {
                    theme: "colored",
                    type: "success"
                })
                setLoadingForm(false)
                form.reset()
            })
            .catch(err => {
                console.log(err)
                setLoadingForm(false)
                toast("Error occur, please try again")
            })
    }
    return (
        <form
            onSubmit={handleOnSubmit}
            className="form mt-0 flex w-full flex-col  items-start justify-start gap-6 overflow-hidden rounded-[10px] border border-black bg-white "
        >
            <div className="w-full bg-primary-color px-4 py-4 font-semibold text-white">
                Book a Free Consultation Now!
            </div>
            <div className="h-full w-full p-8">
                <input
                    name="name"
                    placeholder="Your Name"
                    type="text"
                    required
                    className="mb-8 w-full border-b border-[#23232366]  bg-transparent pb-4 placeholder:text-lg placeholder:text-[#23232366] focus:outline-none"
                />
                <input
                    name="email"
                    placeholder="Your Mail"
                    type="text"
                    required
                    pattern="\S+@\S+\.\S+"
                    className="w-full border-b border-[#23232366] bg-transparent pb-4 placeholder:text-lg placeholder:text-[#23232366] focus:outline-none"
                />

                <Button
                    btnType="submit"
                    type="black"
                    className={`ml-auto mt-6 block max-h-[53px] max-w-[223px] rounded-full bg-black px-6 py-4 uppercase leading-[32px] tracking-[0.2em] text-white hover:border hover:border-black
                                     hover:bg-transparent hover:text-black md:!text-[16px]
                                    lg:!px-8 ${
                                        loadingForm
                                            ? "pointer-events-none whitespace-nowrap !bg-gray-400 "
                                            : ""
                                    }
                                    `}
                >
                    Book a call {loadingForm && <SpinnerLoader />}
                </Button>
            </div>
        </form>
    )
}

export default HeroForm
