import React from "react"
import Blob from "./../ServiceLanding/Hero/Blob"
import parse from "html-react-parser"
import HeroForm from "../Form/heroForm"
// OUR WORK HERO

function NewLandingHero(props) {
    const { title, description, sub, preheader } = props

    const scrollDown = () => {
        let pageHeight = window.innerHeight - 100
        window.scrollBy({
            top: pageHeight,
            behavior: "smooth"
        })
    }

    return (
        <section className="relative isolate grid min-h-[calc(100vh-75px)] items-center overflow-x-clip lg:h-[calc(100vh-104px)]">
            <div className="absolute top-1/2 left-1/2 z-[-1] w-full -translate-x-1/2 -translate-y-1/2 lg:left-0 lg:top-[-20%] lg:max-w-7xl lg:translate-y-0 lg:-translate-x-1/3">
                <Blob />
            </div>

            <div className="container mx-auto">
                <div className="border-box mx-auto mt-5 grid grid-rows-4">
                    <div className="row-span-4 grid grid-cols-12 flex-wrap place-items-center items-center md:row-span-3 md:items-start">
                        <div className="place col-span-full mt-14 flex max-w-[540px] flex-col items-center text-center md:col-span-6 md:mt-0 md:items-start md:items-start md:text-left   ">
                            <p className="mb-4 text-2xl font-semibold text-primary-color">
                                {preheader}
                            </p>
                            <h1 className="page-title mb-4 text-[54px] font-bold leading-[75px]">
                                {title}
                                <br />
                            </h1>
                            <p className=" w-5/6 max-w-[490px]  text-center text-base leading-[22px] tracking-[-0.025rem] text-[#1D2333]/[0.8] md:w-full md:text-left md:text-lg ">
                                {description && parse(description)}
                                {sub && (
                                    <p className="hero-text hero-text--sub font-bold text-[#1D2333]">
                                        {sub}
                                    </p>
                                )}
                            </p>
                        </div>
                        <div className="z-[2]  col-span-full mt-[49px] flex w-full max-w-[541px] md:col-span-6 md:mt-0 md:pl-10   ">
                            <HeroForm />
                        </div>
                    </div>
                </div>
            </div>
            <div className=" hidden md:block">
                <button className="scroll-down">
                    <div onClick={scrollDown} className="mouse"></div>
                    <p className="pre-header">SCROLL DOWN</p>
                </button>
            </div>
        </section>
    )
}

export default NewLandingHero
