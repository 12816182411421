import React, { useEffect, useRef } from "react"
import computer1 from "./images/computer-1.png"
import computer2 from "./images/computer-2.png"
import Button from "../../Button/Button"
import gsap from "gsap"
import arrow from "./images/arrow.svg"
import ScrollTrigger from "gsap/ScrollTrigger"
if (typeof window !== undefined) {
    gsap.registerPlugin(ScrollTrigger)
}

const SiteAudit = () => {
    const $wrapper = useRef(null)

    const scrollToForm = () => {
        const form = document.getElementById("page-form")
        form.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
        const ctx = gsap.context(() => {
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: $wrapper.current,
                    once: true,
                    // markers: true,
                    start: "top bottom-=50%",
                    end: "top bottom-=50%"
                }
            })
            tl.from(".computer-1", {
                x: -20,
                opacity: 0,
                duration: 0.5
            })
                .from(".computer-2", {
                    opacity: 0,
                    duration: 0.5
                })
                .from(".arrow", {
                    opacity: 0,
                    duration: 0.3
                })
        })
        return () => ctx.revert()
    }, [])

    return (
        <div ref={$wrapper} className="bg-dark-gunmetal">
            <div className="container max-w-[1112px] rounded-lg  py-10 px-10 md:px-14 lg:px-16">
                <div className="flex flex-wrap gap-y-10">
                    <div className="flex w-full flex-col justify-center gap-4 pr-10 text-white md:w-1/2 md:pr-20 lg:pr-24 xl:pr-28">
                        <h2 className="mb-2 text-3xl font-semibold lg:text-5xl lg:leading-[67px]">
                            Site Audit & Technical SEO
                        </h2>
                        <p className="mb-4 max-w-[444px] text-base">
                            <span className="mb-3 inline-block font-semibold text-primary-color">
                                Is your website performing at its best?
                            </span>{" "}
                            <br />
                            Don't leave your online success to chance.
                            Introducing Saigon Digital’s Site Audits and
                            Technical SEO, an all in one solution designed to
                            supercharge your website's SEO and performance.
                        </p>
                        <Button
                            onClick={scrollToForm}
                            type="white"
                            className="!hover:bg-primary-color z-10 max-w-[223px] tracking-[0.2em] !text-primary-color hover:!text-white"
                        >
                            Book a call
                        </Button>
                    </div>

                    <div className="relative aspect-square max-h-[459px] w-full rounded-[10px] bg-primary-color p-6 md:w-1/2">
                        <img
                            src={arrow}
                            alt="arrow"
                            className="arrow absolute left-1/4 bottom-[60%] h-1/6  w-1/6"
                        />
                        <img
                            src={computer1}
                            className="computer-1 absolute bottom-6 left-[10%] z-10 h-1/2 max-h-[350px] w-1/2 object-contain lg:h-1/2 "
                            alt="site audit"
                        />
                        <img
                            src={computer2}
                            className="computer-2 absolute bottom-6 right-[10%] z-0 h-[80%] max-h-[350px] w-[1/4] object-contain lg:h-full"
                            alt="site audit"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SiteAudit
