import React from 'react'

const CelerityResult = ({title="Celerity - Case Study",
description="Success with Business Growth Accelerator"}) => {
  return (
    <div className="relative z-10 flex min-h-[40vh] flex-col items-center justify-center py-20 text-center  lg:py-20">
                <img
                    src="/case-study/celerity/result.png"
                    className="absolute inset-0 z-0 h-[calc(100%+5px)] w-full object-cover"
                    alt="background"
                />
                <img
                    src="/case-study/celerity/logo-small.svg"
                    className="z-10 max-w-[136px]"
                    alt="logo small"
                />
                <h2 className='text-5xl font-semibold text-white z-10 my-3 mt-6'>{title}</h2>
                <p className="z-10 max-w-[755px] px-5 text-xl font-medium text-white ">
                  {description}
                </p>
            </div>
  )
}

export default CelerityResult