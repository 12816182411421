import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
if (typeof window !== undefined) {
    gsap.registerPlugin(ScrollTrigger)
}
const Banner = ({ className }) => {
    const slideRef = useRef(null)
    const $wrapper = useRef(null)
    useEffect(() => {
        const ctx = gsap.context(() => {
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: $wrapper.current,
                    once: true,
                    // markers: true,
                    start: "top center",
                    end: "top center"
                }
            })
            tl.from(slideRef.current, {
                opacity: 0,
                yPercent: 10,
                duration: 0.5
            })
        })
    }, [])

    return (
        <section
            ref={$wrapper}
            className={`relative mb-20 flex min-h-[357px] w-full flex-col items-center bg-white pt-16 text-white ${className}`}
        >
            <div className="container relative flex flex-col items-center">
                <img
                    src="/new-landing/banner-shape.svg"
                    alt="texture"
                    className="absolute bottom-0 left-1/2 w-[150%] -translate-x-1/2 lg:w-[100%] lg:translate-y-[180px]"
                />
                <h2 className=" mb-4 text-center text-2xl font-semibold text-primary-color md:text-4xl lg:text-5xl lg:leading-[67px]">
                    Saigon Digital Performance Dashboard
                </h2>
                <p className="max-w-[540px] text-center text-base font-medium text-dark-gunmetal">
                    As part of our Business Growth Accelerator harness the power of your data and get real-time analytics and insights with our Performance
                    Dashboard.
                </p>
                <div
                    // onMouseOver={onMouseEnter}
                    ref={slideRef}
                    className="relative z-[5] aspect-[768/460] w-full max-w-[862px] translate-y-28 lg:w-[60%] lg:-translate-x-16"
                >
                    <img
                        ref={slideRef}
                        src={"/macbook.png"}
                        alt="img"
                        className="absolute top-0 left-0 z-0 aspect-[768/460] h-full w-full max-w-[768px] object-contain lg:-translate-x-10"
                    />
                </div>
            </div>
        </section>
    )
}

export default Banner
