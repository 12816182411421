import React from "react"
import rect from "./images/rectangle.png"
import data from "./images/data-collection.png"
import list from "./images/list.png"
import performance from "./images/performance.png"
import ui from "./images/ui-design.png"
import architechture from "./images/architechture.png"
const cards = [
    {
        image: data,
        title: "Crawlability",
        description: `Search engines send bots (also known as crawlers or spiders) to explore and understand your website. Technical SEO ensures that these bots can easily navigate and access all your website's pages, including important content.`
    },
    {
        image: list,
        title: "Indexability",
        description: `Once the bots crawl your site, they index its pages in their databases. Technical SEO helps ensure that your content is structured and organized in a way that search engines can easily categorize and present to users in search results.`
    },
    {
        image: performance,
        title: "Website Speed",
        description: `Page loading speed is crucial for user experience and SEO. Technical SEO addresses factors that contribute to slow loading times, such as code optimization, image compression, and minimizing server response times.`
    },
    {
        image: ui,
        title: "Mobile Friendliness",
        description: `With the majority of internet users accessing websites through mobile devices, ensuring your site is responsive & mobile-friendly is essential. Technical SEO focuses on optimising your site's design and functionality for various screen sizes.`
    },
    {
        image: architechture,
        title: "Website Architecture",
        description: `Technical SEO involves creating a clear and logical website structure, making it easier for both users and search engines to navigate through your content.`
    }
]

const KeyConcepts = ({ className }) => {
    return (
        <div
            className={`container relative flex flex-col items-center gap-0  py-16 lg:py-20  lg:pt-[140px] ${className}`}
        >
            <p className="mb-4 max-w-[713px] text-center text-2xl font-semibold leading-[28px] text-primary-color">
                Site Audits & Technical SEO
            </p>
            <h2 className="mb-14 max-w-[835px] text-center text-3xl font-semibold md:text-4xl lg:text-5xl lg:leading-[60px]">
                 Key Concepts
            </h2>
            <div className="flex flex-wrap  justify-center gap-x-5 gap-y-10 lg:gap-x-8">
                {cards.map((item, index) => {
                    return (
                        <Card
                            key={index}
                            {...item}
                            className={
                                "w-full cursor-pointer transition-all duration-[800ms] hover:scale-105  md:w-[calc(50%-10px)] lg:w-[calc(30%-10px)]"
                            }
                        />
                    )
                })}
            </div>
        </div>
    )
}

const Card = ({ image, title, description, className }) => {
    return (
        <div className={`relative z-[5] w-full p-6 ${className}`}>
            <img
                src={rect}
                className="absolute inset-0 z-0 h-full w-full object-fill "
                alt="rect"
            />
            <div className="relative z-10 mb-6 flex items-center gap-4">
                <img className="h-12 w-12 " src={image} />
                <h4 className="text-xl font-semibold text-primary-color">
                    {title}
                </h4>
            </div>
            <p className="relative z-10 text-base text-dark-gunmetal">
                {description}
            </p>
        </div>
    )
}

export default KeyConcepts
