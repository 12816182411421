import gsap from "gsap"
import ScrollTrigger from "gsap/dist/ScrollTrigger"
import React, { useEffect } from "react"
import { useRef } from "react"
import phone from "./images/Phone_02.png"
import ProgressCircle from "./progressCircle"
// import "./ContentImage.scss"

if (typeof window !== "undefined") {
    gsap.registerPlugin(ScrollTrigger)
}
const lhScores = [
    {
        title: "Performance",
        score: 99
    },
    {
        title: "Accessibility",
        score: 94
    },
    {
        title: "Best Practices",
        score: 100
    },
    {
        title: "SEO",
        score: 100
    }
]

const Overview = ({ children, img, className }) => {
    const $contentImage = useRef(null)
    const $content = useRef(null)
    const $image = useRef(null)
    const $imageOverlay = useRef(null)

    useEffect(() => {
        const mm = gsap.matchMedia($contentImage)
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: $contentImage.current,
                start: "top top+=20%",
                end: "top top+=20%",
                once: true
                // markers: true
            },
            defaults: {}
        })

        mm.add("(min-width: 1px)", () => {
            ScrollTrigger.create({
                trigger: $contentImage.current,
                once: true,
                // markers: true,
                start: "top center",
                end: "top center",
                onEnter: () => {
                    const progresses = gsap.utils.toArray(".ko-progress-circle")

                    progresses.forEach((progress, index) => {
                        progress.dataset.progress = lhScores?.[index]?.score
                    })
                }
            })
        })
        tl.from($image.current, {
            x: 40,
            opacity: 0
        })
        return () => {
            mm.revert()
        }
    }, [])
    return (
        <section
            className={`${className}  top-0 left-0 w-full py-20 transition-opacity duration-300 `}
            ref={$contentImage}
        >
            <div class="container mb-10 grid gap-y-5 md:grid-cols-12 md:gap-x-[30px] lg:mb-16">
                <div
                    class="order-2 w-full md:order-first md:col-span-7"
                    ref={$content}
                >
                    <div className="flex flex-col items-start gap-6 text-[#232323]">
                        <div className="flex w-full max-w-[540px] flex-col gap-4 font-normal">
                            <h4 className="text-2xl font-semibold">Overview</h4>
                            <p className="text-[#232323]">
                                Dive into Celerity's success story. With our
                                thorough site audits and dynamic SD Performance
                                Dashboard, we've turbocharged their growth and
                                decision-making.
                            </p>
                            <p className="text-[#232323]">
                                Discover how our Business Growth Accelerator can
                                evolve your success too.{" "}
                                <a
                                    className="font-bold text-[#232323] underline"
                                    href="/case-studies/celerity"
                                >
                                    Read More
                                </a>
                            </p>
                            <p className="text-[#232323]">
                                Live link:
                                <a
                                    href="https://celerity-uk.com/"
                                    className="ml-1 font-semibold text-primary-color underline "
                                >
                                    www.celerity-uk.com
                                </a>
                            </p>
                        </div>
                        <div class="mt-10 grid w-full origin-bottom-left scale-[80%] grid-cols-2 gap-8 lg:grid-cols-4">
                            {lhScores.map(({ title, score }) => {
                                return (
                                    <ProgressCircle
                                        // maxWidth={"105px"}

                                        progress={score}
                                        title={title}
                                    />
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div class="w-full md:col-span-5 ">
                    <div className="relative aspect-square w-full max-w-sm overflow-hidden md:max-w-none">
                        <img
                            src={phone}
                            alt="content image"
                            srcset=""
                            className="aspect-auto h-full max-h-[551px] w-full object-cover"
                            ref={$image}
                        />

                        <div
                            className="absolute -inset-2 bg-white opacity-0"
                            ref={$imageOverlay}
                        ></div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Overview
