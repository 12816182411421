import React, { Suspense } from "react"
// import FLSWork from "../components/HomePage/FLSWork/FLSWork"
// import Hero from "../components/HomePage/Hero/Hero"
// import NewWork from "../components/HomePage/NewWork/NewWork"
// import RatingText from "../components/HomePage/RatingText/RatingText"
// import BlogCards from "../components/LatestBlogs/LatestBlogs"
import Layout from "../../components/layout"
import NewLandingHero from "../../components/Hero/NewLandingHero"
import { SEO } from "../../components/seo"

import BenefitHighlight from "../../components/NewLanding/BenefitHighlight/BenefitHighlight"
import ContactFormV1 from "../../components/NewLanding/ContactForm/ContactFormV1"
import StaticTestimonial from "../../components/Testimonial/StaticTesimonial"
import Banner from "../../components/NewLanding/Banner/Banner"
import WhyChooseUs from "../../components/NewLanding/WhyChooseUs"
import SiteAudit from "../../components/NewLanding/SiteAudit"
import KeyConcepts from "../../components/NewLanding/KeyConcepts"
import CelerityResult from "../../components/CaseStudy/celerityResult"
import OurProcess from "../../components/NewLanding/OurProcess"
import Overview from "../../components/NewLanding/Overview"
import FAQ from "../../components/FAQ/FAQ"
import faqs from "../../data/faq.json"
import { Helmet } from "react-helmet"
import localisationData from '../../data/localisation.json'
import ThreeColumnContent from "../../components/ThreeColumnContent"

function Home({ location }) {
    const heroData = {
        preheader: "Business Growth Accelerator",
        title: "Boost your business success.",
        description: `<strong className="mb-2">Get a completely free Site Audit today!</strong> <br/>
        We can help with a free consultation to boost your online presence. Ready to get some instant quick wins for your online presence?`
    }
    const context = {
        pageName: "SD | Services - Business Growth Accelerator",
        pageUri: location.href
    }
    return (
        <Layout context={context}>
            <Suspense
                fallback={
                    <div className="container grid min-h-screen place-items-center"></div>
                }
            >
                <main className="new-landing">
                    <NewLandingHero {...heroData} />
                    {/* <RatingText /> */}

                    <Banner />
                    <ContactFormV1
                        className={"mt-[120px] pt-20 md:mb-10 lg:pt-[130px]"}
                    />
                    <WhyChooseUs />
                    <BenefitHighlight />
                    <SiteAudit />
                    <KeyConcepts />
                    <OurProcess />
                    <CelerityResult />
                    <Overview />
                    <div className=" mt-0">
                        <StaticTestimonial
                            title={"Real Success Stories"}
                            subTitle={"Thoughts from the Celerity Team"}
                            content={
                                <p>
                                    Saigon Digital's technical expertise has
                                    transformed Celerity's digital presence -
                                    the development of our new website has
                                    resulted in improvements across user
                                    experience, lead generation, and organic
                                    search performance. If we need advice or
                                    assistance, the response is immediate - it's
                                    like having an extension to the team.
                                </p>
                            }
                            author={"Callum Graham-Rack"}
                            position={"Marketing Assistant at Celerity UK"}
                        />
                    </div>
                    <ThreeColumnContent localisationData={localisationData.bga}/>
                    <div className="container mt-10 lg:mt-32">
                        <FAQ faqs={faqs.businessGrowFaqs} />
                    </div>
                </main>
            </Suspense>
        </Layout>
    )
}
export default Home
export const Head = () => (
    <>
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "FAQPage",
                    mainEntity: [
                        {
                            "@type": "Question",
                            name: "Why should I choose Saigon Digital's Business Growth Accelerator services?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "Choosing Saigon Digital’s Business Growth Accelerator means partnering with experts dedicated to helping you accelerate your business growth. Our unique methodology, proven success, and commitment to your growth set us apart from others."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "What is a Business Growth Accelerator?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "A Business Growth Accelerator, like the one offered by Saigon Digital, is a programme designed to rapidly accelerate your business growth. It provides strategic planning, expert guidance, and hands-on support to help businesses scale quickly and effectively."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "How does a Business Growth Accelerator differ from traditional consulting?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "Unlike traditional consulting, Saigon Digital’s Business Growth Accelerator focuses on action and results. It’s designed to accelerate your business growth by not just advising but actively implementing growth strategies alongside you."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "What services are included in Saigon Digital’s Business Growth Accelerator programme?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "Saigon Digital’s Business Growth Accelerator includes services such as strategic planning, market analysis, operational optimisation, and ongoing mentorship. These are all aimed at accelerating growth in business by addressing key areas of development."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "What industries do you specialise in for Business Growth Acceleration?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "Saigon Digital specialises in various industries, including technology, retail, and financial services, where we have a proven track record of accelerating growth in business. Our expertise helps tailor our accelerator to specific industry needs."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "What kind of support will I receive during the programme?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "During Business Growth Accelerator, you’ll receive continuous support, including mentorship, strategy adjustments, and regular reviews. This ensures that we are always working to accelerate your business growth effectively."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "What does it cost to participate in Saigon Digital’s Business Growth Accelerator?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "The cost of participating in Saigon Digital’s Business Growth Accelerator varies depending on the services required. However, the investment is aimed at accelerating growth in business, providing significant returns over time."
                            }
                        }
                    ]
                })}
            </script>
        </Helmet>

        <SEO
            title={"Saigon Digital -  Business Growth Accelerator"}
            image={
                "https://cms.saigon.digital/wp-content/uploads/2023/12/BGA-OG.png"
            }
            description={
                "Free Site Audit for SEO & Performance Optimisation. Harness the power of real-time data with our Performance Dashboard. Make smarter decisions, faster."
            }
            keywords={
                "free seo audit, performance dashboard, seo, performance optimisation, data analytics, free seo audit saigon"
            }
        />
    </>
)
