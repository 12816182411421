import React, { useEffect, useRef, useState } from "react"
import parse from "html-react-parser"
const Accordion = ({ question, answer, setOpenKey, openKey, currentKey }) => {
    const [height, setHeight] = useState(0)
    const $ref = useRef(null)
    console.log(currentKey)
    const open = currentKey === openKey
    useEffect(() => {
        const event = window.addEventListener("resize", () => {
            setHeight($ref.current.clientHeight || 0)
        })
        return () => window.removeEventListener("resize", event)
    }, [])
    return (
        <div className="mt-4 flex w-full flex-col">
            <div
                onClick={() => setOpenKey(currentKey)}
                className={`flex w-full items-center justify-between border-b  bg-white text-dark-gunmetal ${
                    open ? "border-none pb-0" : "border-[#E9E9E9] pb-4"
                } px-0  `}
            >
                <div className="flex w-[80%] justify-start lg:w-[70%]">
                    <div className="w-6 ">
                        <span className="grid h-6 w-6  place-items-center items-center justify-center rounded-full bg-primary-color text-white">
                            {currentKey + 1}
                        </span>
                    </div>
                    <h3 className="ml-4 select-none text-base font-semibold lg:ml-8 lg:text-xl">
                        {question}
                    </h3>
                </div>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={13}
                    height={7}
                    viewBox="0 0 13 7"
                    fill="none"
                    className={`cursor-pointer transition ${
                        open
                            ? "rotate-90 text-primary-color"
                            : "rotate-180 text-[#A3A6B1]"
                    }`}
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.9131 6.25879C11.5952 6.56571 11.0888 6.55686 10.7819 6.23903L6.62218 1.93138L2.31453 6.09107C1.9967 6.39798 1.49024 6.38913 1.18333 6.0713C0.876414 5.75347 0.885264 5.24702 1.2031 4.9401L6.08623 0.224699C6.40406 -0.0822148 6.91051 -0.0733648 7.21742 0.244465L11.9328 5.1276C12.2397 5.44543 12.2309 5.95188 11.9131 6.25879Z"
                        fill="currentcolor"
                    />
                </svg>
            </div>
            <div
                ref={$ref}
                style={{
                    maxHeight: open ? "700px" : "0",
                    padding: open ? "" : "0"
                }}
                className="w-full select-none overflow-hidden p-4  pl-10 text-left text-lg transition-all duration-300 md:pb-14 lg:pl-14"
            >
                {parse(answer)}
            </div>
        </div>
    )
}

Accordion.defaultProps = {
    question: "What type of projects do you take on?",
    answer: `We only ever take on projects with a strategic outlook – we’re driven by solving problems and seeing the needle move in some way, whether that’s increasing conversions, SEO results, lead generation or any other marketing objective you might be working towards. Our consultative process and strategic approach to projects reflect this.
    `
}

export default Accordion
