import React, { useRef, useState, useEffect } from "react"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import parse from "html-react-parser"
if (typeof window !== undefined) {
    gsap.registerPlugin(ScrollTrigger)
}
export const matchMediaScreen = {
    large: "(min-width: 1024px)",
    small: "(max-width: 1023px)"
}

const Card = ({
    targetEl,
    reserve_layout,
    title,
    description,
    customLink,
    caseStudy,
    image,
    id,
    setActiveCase
}) => {
    const cardRef = useRef(null)
    useEffect(() => {
        const mm = gsap.matchMedia()
        mm.add(
            matchMediaScreen.large,
            () => {
                const title = cardRef.current.querySelector(".title")
                const description =
                    cardRef.current.querySelector(".description")
                const btn = cardRef.current.querySelector(".btn-for-gsap")

                const tl = gsap.timeline()

                const moveTarget = () => {
                    setActiveCase(id)
                    gsap.to(targetEl?.current, {
                        xPercent: reserve_layout ? 115 : 0,
                        // rotateY: reserve_layout ? -20 : 0,
                        rotateY: reserve_layout ? -180 : 0,
                        ease: "elastic",

                        overwrite: true,
                        duration: 1.3
                    })
                }

                ScrollTrigger.create({
                    trigger: cardRef.current,
                    // markers: true,
                    start: "top-=450 top",
                    end: "bottom-=380 top",

                    animation: tl,
                    toggleActions: "play none none reverse",
                    onEnter: () => {
                        moveTarget()
                    },

                    onEnterBack: () => {
                        moveTarget()
                    }
                })

                tl.from([title, description, btn], {
                    opacity: 0,
                    y: 20,
                    stagger: 0.2
                })
            },
            cardRef
        )

        return () => mm.revert()
    }, [targetEl])

    return (
        <div
            className={`relative flex flex-col gap-y-8 lg:items-center lg:gap-x-5 ${
                reserve_layout ? "lg:flex-row-reverse" : "lg:flex-row"
            }`}
            ref={cardRef}
        >
            <div className="relative z-10 w-full lg:pointer-events-none lg:invisible lg:flex  lg:w-[60%]">
                <div className="aspect-video w-full rounded-[20px] ">
                    <div className="boder-solid relative h-full w-full overflow-hidden rounded-b-[20px] ">
                        <img
                            src={image}
                            sizes="100vw"
                            className="absolute inset-0 h-full w-full object-contain"
                            alt={"benefit hightlight"}
                        />
                    </div>
                </div>
            </div>
            <div className="relative z-10  flex flex-col items-center md:items-start lg:flex-1">
                {title && (
                    <h3 className="title font-neue-ultrabold text-very-light-blue mb-5 text-center text-xl font-semibold md:text-left lg:text-[32px] lg:text-2xl">
                        {title}
                    </h3>
                )}
                {description && (
                    <p className="description mb-4 max-w-md text-center text-base leading-[24px] md:max-w-lg md:text-left lg:max-w-none ">
                        {parse(description)}
                    </p>
                )}
            </div>
        </div>
    )
}

export default Card
