import React, { useRef, useState } from "react"
import dashboard from "./images/Dashboard.png"
import Accordion from "./Accordion"
const listQuestion = [
    {
        question: "SD Dashboard Setup and Onboarding",
        answer: `Initial consultation to understand what the main KPI's and Metrics you
        and your business want to measure. From there, let Saigon Digital set up
        a customised Dashboard that will give you the insights and data you want.
    `
    },
    {
        question: "Site Audits & Technical SEO",
        answer: `<ul className="list-disc">
        
        <li> Don't let technical glitches hinder your website's potential. Saigon Digital will
        provide insightful reports that which can then be analysed and actioned,
        may it be<strong> content </strong> recommendations or<strong> technical SEO </strong> improvements.</li>
        <li><strong> Actionable Reports </strong> - Our detailed reports provide clear action steps to
        tackle issues head-on and drive improvements. </li>
        </ul>
    `
    },
    {
        question: "Checkins and Monitoring",
        answer: `Based on the initial consultation and the delivery plan, Saigon Digital will host meetings to check in on the performance and website health and make informed decisions based on the data and metrics obtained.
    `
    }
]
const OurProcess = ({ className }) => {
    const [openKey, setOpenKey] = useState(0)
    const imgRef = useRef(null)
    return (
        <div
            className={`container relative flex flex-col items-center  py-10 lg:py-20   ${className}`}
        >
            <h2 className=" mb-6 max-w-[835px] text-center text-3xl font-semibold md:text-4xl  lg:text-5xl lg:leading-[60px]">
                Our Process
            </h2>
            <p className=" mb-14 max-w-[540px] text-center text-base  font-medium leading-[28px] text-dark-gunmetal">
                We understand your goals, tailor a growth strategy, and track
                progress. Your success is our mission.
            </p>
            <div className="flex flex-col  gap-y-10 md:flex-row">
                <div className="mt-6 w-full md:w-1/2">
                    {listQuestion.map((ele, index) => {
                        return (
                            <Accordion
                                openKey={openKey}
                                currentKey={index}
                                setOpenKey={setOpenKey}
                                key={index}
                                question={ele.question}
                                answer={ele.answer}
                            />
                        )
                    })}
                </div>
                <div className="w-full md:w-1/2 md:pl-10">
                    <img
                        src={dashboard}
                        alt="dashboard"
                        className="aspect-[540/523] w-full object-cover lg:max-h-[523px] "
                    />
                </div>
            </div>
        </div>
    )
}

export default OurProcess
