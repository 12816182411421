import React, { useEffect, useRef, useState } from "react"
import Button from "../../Button/Button"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/all"
import axios from "axios"
import { toast } from "react-toastify"
import BeforeAfterSlide from "../BeforeAfterSlide/BeforeAfterSlide"
import CONSTANT_INFO from "../../../constant/constant"
import SpinnerLoader from "../../Loader/SpinnerLoader"
if (typeof window !== undefined) {
    gsap.registerPlugin(ScrollTrigger)
}
const ContactFormV1 = ({
    className,
    context = {
        pageName: "Saigon Digital",
        pageUri: "https://saigon.digital/"
    }
}) => {
    const divRef = useRef(null)
    const [start, setStart] = useState(false)

    const [loadingForm, setLoadingForm] = useState()

    const handleOnSubmit = async e => {
        e.preventDefault()
        const form = e.target
        setLoadingForm(true)
        const formData = new FormData(e.target)

        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        }
        console.log(process.env.GATSBY_HUBSPOT_FORM_NEWLANDING)
        console.log(CONSTANT_INFO.curl.hubspot_form_newlanding)

        const fields = [
            {
                name: "firstname",
                value: formData.get("name")
            },

            {
                name: "email",
                value: formData.get("email")
            },
            {
                name: "website",
                value: formData.get("website")
            }
        ]
        console.log(fields)
        await axios
            .post(
                CONSTANT_INFO.curl.hubspot_form_newlanding,
                {
                    portalId: process.env.GATSBY_HUBSPOT_PORTAL,
                    formGuid: process.env.GATSBY_HUBSPOT_FORM_NEWLANDING,
                    context: context,
                    fields
                },
                config
            )
            .then(res => {
                toast(res?.data?.inlineMessage, {
                    theme: "colored",
                    type: "success"
                })
                setLoadingForm(false)
                form.reset()
            })
            .catch(err => {
                console.log(err)
                setLoadingForm(false)
                toast("Error occur, please try again")
            })
    }

    useEffect(() => {
        const ctx = gsap.context(() => {
            const tl = gsap
                .timeline({
                    scrollTrigger: {
                        trigger: divRef.current,
                        start: "top top+=30%",
                        end: "top top+=20%",
                        once: true
                    }
                })
                .fromTo(
                    ".phone",
                    {
                        x: 50,
                        opacity: 0
                    },
                    { x: -20, opacity: 1 }
                )
        })
    }, [])
    return (
        <div ref={divRef} className={` bg-white py-16 lg:py-20 ${className}`}>
            <div className="container flex flex-col gap-y-14 md:flex-row">
                <div
                    className=" grid w-full grid-cols-6 flex-col items-center gap-4 text-dark-gunmetal  md:w-1/2 md:items-start 
                md:pr-14 lg:pr-28"
                >
                    <h4 className="col-span-full text-center text-4xl font-semibold md:text-left lg:text-5xl lg:leading-[67px] ">
                        Site Audit Review
                    </h4>
                    <p className="col-span-full text-center text-base font-medium text-dark-gunmetal  md:text-left">
                        Our free Site Audit is tailored to boost your website's potential. The Report will
                        provide areas of imorovement and a clear, easy-to-implement plan of action.
                    </p>
                    <form
                        onSubmit={handleOnSubmit}
                        className="form col-span-full mt-4 flex flex-col items-start gap-6 md:col-span-5"
                    >
                        <input
                            name="name"
                            placeholder="Your Name"
                            type="text"
                            required
                            className="w-full border-b border-[#A3A6B1]  bg-transparent pb-4 placeholder:text-lg placeholder:text-[#A3A6B1] focus:outline-none"
                        />
                        <input
                            name="email"
                            placeholder="Your Mail"
                            type="text"
                            required
                            pattern="\S+@\S+\.\S+"
                            className="w-full border-b border-[#A3A6B1] bg-transparent pb-4 placeholder:text-lg placeholder:text-[#A3A6B1] focus:outline-none"
                        />
                        <input
                            name="website"
                            placeholder="Website URL"
                            type="text"
                            className="w-full border-b border-[#A3A6B1] bg-transparent pb-4 placeholder:text-lg placeholder:text-[#A3A6B1] focus:outline-none"
                        />
                        <Button
                            btnType="submit"
                            type="blue"
                            className={`mx-auto mt-6 rounded-full border-[2px] border-primary-color bg-primary-color !py-3
                             px-5 uppercase tracking-[0.2em] text-white hover:border hover:border-primary-color hover:bg-white
                        hover:text-primary-color md:mx-0  ${
                            loadingForm
                                ? "pointer-events-none whitespace-nowrap !bg-gray-400 "
                                : ""
                        }
                        `}
                        >
                            get free audit! {loadingForm && <SpinnerLoader />}
                        </Button>
                    </form>
                </div>
                <div className="relative w-full md:w-1/2">
                    <BeforeAfterSlide />
                </div>
            </div>
        </div>
    )
}

export default ContactFormV1
