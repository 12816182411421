import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import texture from "./images/Texture.svg"
import Image from "gatsby-image"
import before from "./images/before.png"
import after from "./images/after.png"
import Draggable from "react-draggable"
import { useMediaQuery } from "../../../hooks/useMediaQuery"

const BeforeAfterSlide = ({ className }) => {
    const slideRef = useRef()
    const imageRef = useRef(null)
    const [slidePosition, setSlidePosition] = useState(50)
    const isMobile = useMediaQuery("(max-width: 768px)")
    // const [linePosition, setLineP    ostion] = useState({ x: 0 })
    const [mousePosition, setMousePosition] = useState(0)
    const lineRef = useRef()
    const onMouseEnter = e => {
        let mouseX
        if (isMobile) {
            mouseX = e.touches[0].clientX
        } else {
            mouseX = e.clientX
        }
        const width = imageRef.current.offsetWidth || 500
        const slideRect = slideRef.current.getBoundingClientRect()
        const offsetLeft = mouseX - slideRect.x - 13
        const offsetRatio = ((offsetLeft + 13) / slideRect.width) * 100

        if (isMobile) {
            if (offsetLeft < 20 || offsetLeft > width - 40) return
        }
        if (offsetLeft < 10 || offsetLeft > width - 20) return
        setMousePosition(offsetLeft)
        setSlidePosition(offsetRatio)
    }

    const slideRefClass = ``
    useEffect(() => {
        if (!isMobile) {
            slideRef?.current?.addEventListener("mousemove", onMouseEnter)
        } else {
            slideRef?.current?.addEventListener("touchmove", onMouseEnter)
        }

        return () => {
            if (!isMobile) {
                slideRef?.current?.removeEventListener(
                    "mousemove",
                    onMouseEnter
                )
            } else {
                slideRef?.current?.removeEventListener(
                    "touchmove",
                    onMouseEnter
                )
            }
        }
    }, [])

    return (
        <section
            className={`relative  flex min-h-[357px] w-full flex-col items-center overflow-visible bg-white  text-white ${className}`}
        >
            <div className=" relative flex h-full w-full flex-col items-center">
                <div
                    // onMouseOver={onMouseEnter}
                    ref={slideRef}
                    className="z[5] relative aspect-[540/429] w-full max-w-[862px]  lg:w-[90%]"
                >
                    {/* <div className="overlay absolute top-0 left-0 z-50 h-full w-full"></div> */}

                    <div
                        style={{
                            left: mousePosition > 0 ? `${mousePosition}px` : ``
                        }}
                        ref={lineRef}
                        className={`item-center  absolute left-[calc(50%-13px)] z-[6] flex h-full w-6 cursor-pointer justify-center`}
                    >
                        <div
                            className="after:color-white relative z-[10] h-full w-[2px] cursor-pointer bg-primary-color
                    before:absolute before:top-[80px] before:right-3 before:grid before:place-items-center 
                    before:rounded-md before:bg-primary-color before:py-[10px] before:px-[16px]
                     before:text-sm before:content-['Before'] after:absolute after:top-[95px] after:left-3 after:rounded-md after:bg-[#1E0F48]
                        after:p-2 after:py-[10px] after:px-[16px] after:text-sm 
                    after:content-['After'] md:before:text-base md:after:text-base
                    "
                        ></div>
                    </div>
                    <div
                        ref={imageRef}
                        style={{
                            clipPath: `inset(0 ${100 - slidePosition}% 0 0)`
                        }}
                        className="relative z-[5] h-full w-full overflow-hidden bg-white"
                    >
                        <img
                            src={before}
                            alt="img"
                            className="absolute top-0 left-0 h-full w-full object-cover"
                        />
                    </div>
                    <img
                        src={after}
                        alt="img"
                        className="absolute top-0 left-0 z-0 h-full w-full object-cover"
                    />
                </div>
            </div>
        </section>
    )
}

export default BeforeAfterSlide
